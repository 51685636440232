import React from 'react'
//import { Link } from 'gatsby'
import BasicLayout from 'src/components/BasicLayout'
import { RawHiringPage } from 'src/components/jobs'

function IndexPage() {

  return (
    <BasicLayout>
      <p>This page list jobs for current and prospective students, as well as programmers looking to contribute to research projects (MLOps, etc.)</p>
      <RawHiringPage /> 
    </BasicLayout>
  )
}

export default IndexPage